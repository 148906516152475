import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../../store/store"

const StationDetailsSlice = createSlice({
    name: "stationDetails",
    initialState: {stationId: null, viewCompanyRole: null} as {
        stationId: null | number | undefined
        viewCompanyRole: null | string | undefined
    },
    reducers: {
        setStationDetails: (
            state,
            {
                payload: {stationId, viewCompanyRole},
            }: PayloadAction<{stationId: null | number | undefined; viewCompanyRole: null | string | undefined}>
        ) => {
            state.stationId = stationId
            state.viewCompanyRole = viewCompanyRole
        },
    },
})

export const {setStationDetails} = StationDetailsSlice.actions

export default StationDetailsSlice.reducer
export const getStationDetails = (state: RootState) => state.stationDetails
