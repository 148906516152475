import {source} from "./imageUrls"
export const MenuListHub: any = [
    {
        icon: source.menu.ChargerManagementIcon,
        name: "Company Management",
        path: "/company-management",
        child: [
            {
                icon: "",
                name: "Profile",
                path: "/company-management/profile",
            },
            {
                icon: "",
                name: "Registration",
                path: "/company-management/registration",
                submenu_path: "/company-management/registration/details",
            },
            {
                icon: "",
                name: "Mapping",
                path: "/company-management/mapping",
            },
            {
                icon: "",
                name: "Admin",
                path: "/company-management/admin",
            },
            {
                icon: "",
                name: "Settlements",
                path: "/company-management/settlements",
            },
        ],
    },
    {
        icon: source.menu.LocationIcon,
        name: "Locations",
        path: "/locations",
        child: [
            {
                icon: "",
                name: "Stations",
                path: "/locations/stations",
            },
            {
                icon: "",
                name: "Chargers",
                path: "/locations/chargers",
                submenu_path: "/locations/chargers/charger-profile",
            },
        ],
    },
    {
        icon: source.menu.NetworkIcon,
        name: "Network",
        path: "/network",
    },
    {
        icon: source.menu.TariffIcon,
        name: "Tariff",
        path: "/tariff",
    },
    {
        icon: source.menu.TokenIcon,
        name: "Tokens",
        path: "/tokens",
    },
    {
        icon: source.menu.SessionIcon,
        name: "Sessions",
        path: "/sessions",
    },
    {
        icon: source.menu.LogsIcon,
        name: "Logs",
        path: "/logs",
    },
    {
        icon: source.menu.ComplaintIcon,
        name: "Complaints",
        path: "/complaints",
    },
]

export const MenuList: any = [
    {
        icon: source.menu.ChargerManagementIcon,
        name: "Company Management",
        path: "/company-management",
        child: [
            {
                icon: "",
                name: "Profile",
                path: "/company-management/profile",
            },
            {
                icon: "",
                name: "Admin",
                path: "/company-management/admin",
            },
            {
                icon: "",
                name: "Settlements",
                path: "/company-management/settlements",
            },
        ],
    },
    {
        icon: source.menu.LocationIcon,
        name: "Locations",
        path: "/locations",
        child: [
            {
                icon: "",
                name: "Stations",
                path: "/locations/stations",
            },
            {
                icon: "",
                name: "Chargers",
                path: "/locations/chargers",
                submenu_path: "/locations/chargers/charger-profile",
            },
        ],
    },
    {
        icon: source.menu.TariffIcon,
        name: "Tariff",
        path: "/tariff",
    },
    {
        icon: source.menu.TokenIcon,
        name: "Tokens",
        path: "/tokens",
    },
    {
        icon: source.menu.SessionIcon,
        name: "Sessions",
        path: "/sessions",
    },
    {
        icon: source.menu.ComplaintIcon,
        name: "Complaints",
        path: "/complaints",
    },
]
