export const iconSource: any = {
    icons: {
        AccordionIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/accordion.svg",
        ActiveIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/active.svg",
        AttachedFilesIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/attach_files_icon.svg",
        BidirectionalArrowsIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/bidirectional_arrows_icon.svg",
        CalendarIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/calendar_icon.svg",
        CautionIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/caution_icon.svg",
        CheckmarkIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/checkmark.svg",
        CloseIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/close_icon.svg",
        CompletedIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/completed.svg",
        CongratsIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/congrats_icon.svg",
        CopyIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/copy.svg",
        CsvFileIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/csv.svg",
        DealDetailsIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/deal_details_icon.svg",
        DefaultFileIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/default_file.svg",
        DecreaseIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/decrease_icon.svg",
        DeleteIcon: "https://d3orevttu06iqr.cloudfront.net/assets/delete_icon.svg",
        DisconnectedIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/disconnected.svg",
        DownloadIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/download_icon.svg",
        DropdownIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/dropdown_icon.svg",
        EditAltIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/edit_alt_icon.svg",
        EditIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/edit_icon.svg",
        EmailIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/email_icon.svg",
        ErrorIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/error_icon.svg",
        EyeIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/eye_icon.svg",
        ViewFileIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/file_view_icon.svg",
        GridViewIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/grid_view_icon.svg",
        HoldIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/hold_icon.svg",
        IncreaseIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/increase_icon.svg",
        InfoIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/info.svg",
        LeftChevronIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/left_chevron_icon.svg",
        RightChevronIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/right_chevron_icon.svg",
        MenuArrowIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu_arrow.svg",
        MessageIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/message.svg",
        MenuBackArrowIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/back_arrow.svg",
        MobileIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/mobile_icon.svg",
        NavigateArrowIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/navigate_arrow.svg",
        MenuOpenArrowIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/open_arrow.svg",
        PDFIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/pdf.svg",
        PreparingIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/preparing.svg",
        RefreshOrangeIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/refresh_icon_orange.svg",
        RefreshIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/refresh_icon.svg",
        RefetchIcon: "https://d3orevttu06iqr.cloudfront.net/assets/refetch_icon.svg",
        SearchIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/search_icon.svg",
        SelectDropdownIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/select_dropdown_icon.svg",
        SendIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/send_icon.svg",
        StatusCompletedIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/status_completed_icon.svg",
        StatusWarningIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/status_warning_icon.svg",
        TableViewIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/table_view_icon.svg",
        TotalAdminIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/total_admins.svg",
        TotalCPOIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/total_cpo.svg",
        TotaleMSPSIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/total_eMSPs.svg",
        TotalPlatformIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/total_platforms.svg",
        WarningIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/warning.svg",
    },
    ConnectorsIcon: {
        gbt: "https://d3orevttu06iqr.cloudfront.net/assets/a_gbt_icon.svg",
        chademo: "https://d3orevttu06iqr.cloudfront.net/assets/a_chademo_icon.svg",
        "ccs-2": "https://d3orevttu06iqr.cloudfront.net/assets/a_ccs_2_icon.svg",
        wall: "https://d3orevttu06iqr.cloudfront.net/assets/a_awall_icon.svg",
        "type-2": "https://d3orevttu06iqr.cloudfront.net/assets/a_type_2_icon.svg",
    },
}
