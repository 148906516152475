import {styled, Theme, CSSObject} from "@mui/material/styles"
import MuiDrawer, {DrawerProps} from "@mui/material/Drawer"
import {MenuList, MenuListHub} from "../../constants/menuItems"
import MenuListItems from "./menuLists"
import {useLocation} from "react-router-dom"
import userInfo from "../../pages/Common/userInfo"
import List from "@mui/material/List"
import styles from "./SideMenu.module.scss"
import {source} from "../../constants/imageUrls"

const drawerWidth = 246

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    [theme.breakpoints.up("desktop_sm")]: {
        width: "246px !important",
    },
    [theme.breakpoints.between("desktop_md", "desktop_lg")]: {
        width: "295.2px !important",
    },
    [theme.breakpoints.up("desktop_lg")]: {
        width: "369px !important",
    },
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(8)} + 1px)`,
    [theme.breakpoints.up("desktop_sm")]: {
        width: "101px !important",
    },
    [theme.breakpoints.between("desktop_md", "desktop_lg")]: {
        width: "91px !important",
    },
    [theme.breakpoints.up("desktop_lg")]: {
        width: "113px !important",
    },
})

const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: 24,
    paddingRight: 20,

    ...theme.mixins.toolbar,
}))

interface MuiDrawerProps extends DrawerProps {
    isHover?: boolean
}

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== "open" && prop !== "isHover"})<MuiDrawerProps>(
    ({theme, open, isHover}) => ({
        // width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        position: isHover ? "initial" : "sticky",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": {
                ...closedMixin(theme),
                width: isHover && "246px !important",
                [theme.breakpoints.up("desktop_sm")]: {
                    width: isHover && "246px !important",
                },
                [theme.breakpoints.between("desktop_md", "desktop_lg")]: {
                    width: isHover && "295.2px !important",
                },
                [theme.breakpoints.up("desktop_lg")]: {
                    width: isHover && "369px !important",
                },
            },
        }),
    })
)

const SideMenu = ({open, setHover, isHover}: any) => {
    const location = useLocation()

    const userDetails = userInfo()

    const newMenuList = userDetails?.company_role === "hub" ? MenuListHub : MenuList

    return (
        <Drawer
            className={styles.menu_drawer}
            variant="permanent"
            PaperProps={{
                sx: {
                    width: {
                        desktop_sm: 101,
                        desktop_md: 91,
                        desktop_lg: 113,
                    },
                },
            }}
            open={open}
            isHover={isHover}
            onMouseEnter={() => !open && setHover(true)}
            onMouseLeave={() => !open && setHover(false)}
        >
            <DrawerHeader
                sx={
                    open || isHover
                        ? {
                              justifyContent: "initial",
                              marginBottom: "7px",
                          }
                        : {
                              justifyContent: "center",
                              marginBottom: "7px",
                          }
                }
            >
                {open || isHover ? (
                    <img className={styles.menu_drawer__full_logo} src={source.logo.FullLogo} alt="statiq" />
                ) : (
                    <img className={styles.menu_drawer__small_logo} src={source.logo.CompanyLogoIcon} alt="statiq" />
                )}
            </DrawerHeader>
            <List>
                {newMenuList.map((menu: any) => {
                    return (
                        <MenuListItems
                            key={menu.path}
                            {...menu}
                            open={open}
                            isHover={isHover}
                            active={location.pathname}
                        />
                    )
                })}
            </List>
        </Drawer>
    )
}

export default SideMenu
